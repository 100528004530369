@font-face {
  font-family: 'retrogaming';
  src:  url('../fonts/RetroGaming.woff2') format('woff2'),
        url('../fonts/RetroGaming.woff') format('woff');
}

@font-face {
  font-family: 'montserrat';
  src:  url('../fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat-Regular.woff') format('woff');
}