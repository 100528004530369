@import '~sass-mq';
@import './fonts.scss';
@import './variables.scss';

html {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  // prevent pull-refresh since otherwise dragging a piece down
  // could trigger a refresh of the page
  overscroll-behavior-y: contain;
}

*, *:before, *:after {
  box-sizing: inherit;
}

ul.unstyled {
  list-style: none;
  padding: 0;
  margin: 0;
}

.siteWidth {
  max-width: $maxContentWidth;
  margin: 0 auto;

  @include mq($from: medium) {
    padding: 0 15px;
  }
}

body {
  background: url("../img/asteroids.jpg");
  background-color: #000;
  background-repeat: repeat;
  color: $clrTx;
  font-family: sans-serif;
  font-size: 16px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../img/dot-bg2.png");
    background-repeat: repeat;
    opacity: 1;
    z-index: -1;
  }  
}

#root { height: 100%; }

#mainWrap {
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'montserrat', sans-serif;
  font-weight: 600;
  color: #FFF;
}

h1 {
  font-size: 1.5rem;
}

button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.btn {
  border: 1px solid $clrBorder;
  padding: 10px 15px;
  transition: all 0.3s ease;
  text-transform: uppercase;

  &:hover {
    background-color: $clrTx;
    background-color: #fff;
    color: #000;
  }
}

.iconBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: fill 0.6s linear;

  svg {
    fill: $clrTx;

    &:hover {
      fill: #FFF;
    }
  }
}