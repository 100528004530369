@import '~sass-mq';
@import 'styles/variables.scss';

.App {
  display: grid;
  grid-template-rows: fit-content(500px) auto;
  height: 100%;
  margin: 0 auto;

  #mainHeader {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 15px 10px;

    @include mq($from: medium) {
      padding: 20px 0;
    }
    
    & > div {
      display: flex;

      h1 {
        font-family: 'retrogaming', sans-serif;
        font-weight: 600;
        margin: 0;
        line-height: 1;
        flex: 1;
        display: flex;
        align-self: center;
      }

      nav {
        display: flex;

        & > button {
          margin-right: 7px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            fill: $clrTx;
            transition: fill 0.6s ease;

            &:hover {
              fill: #FFF;
            }
          }

          &.active svg {
            fill: #fff;
          }
        }

        .btnPause {
          position: relative;
          left: -2px;
        }

        .btnHelp {
          position: relative;
          right: 2px;
        }

        .btnRestart {
          position: relative;
          top: 1px;
        }
      }
    }
  }

  main {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: 1fr 80px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "tetris tetris tetris"
      "high-score score level";
    position: relative;
    
    #tetris {
      grid-area: tetris;
      align-self: end;
      overflow: hidden;
      box-sizing: content-box;

      & > div {
        margin: 0 auto;
        background-color: #000;
        border: 1px solid rgba(22, 144, 181, 0.25);
        border-top: none;

        @include mq($until: medium) {
          border-bottom: none;
        }
      }
    }

    #tetris-level {
      grid-area: level;
    }

    #tetris-score {
      grid-area: score;
    }

    #tetris-high-score {
      grid-area: high-score;
    }

    & > nav {
      grid-area: nav;
      background-color: white;

      p {
        margin: 0;
      }
    }

    @include mq($from: medium) {
      grid-template-rows: repeat(4, 1fr) minmax(41px, 82px) minmax(41px, 82px);
      grid-template-columns: minmax(82px, 1fr) 2fr minmax(82px, 1fr);
      grid-template-areas:
        ". tetris ."
        ". tetris ."
        ". tetris ."
        ". tetris ."
        "level tetris score"
        "level tetris score";
      column-gap: 10px;

      #tetris-level {
        justify-self: end;
      }

      #tetris-high-score {
        display: none;
      }
    }
  }
}