@import '~sass-mq';
@import 'styles/variables.scss';

.MyModal-overlay, .TextOverlay-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.MyModal-wrap {
  outline: none;
  padding: 25px;
  margin: 0 10px;
  max-width: 420px;
  background-color: #000;
  border: 1px solid $clrBorder;
  box-shadow: 0 1px 2px rgba(255,255,255,0.2);
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 5px;
  right: 5px;
  max-height: calc(100% - 20px);
  overflow: auto;  

  @include mq($from: 'small') {
    position: relative;
    top: 40px;
    margin: 0 auto;
    max-height: calc(100% - 60px);
  }

  h1 {
    margin-top: 0;
  }

  .MyModal-btnClose {
    position: absolute;
    top: 0;
    right: 0;

    svg {
      fill: $clrTx;
      width: 50px;
      height: 50px;
    }
  }

  .MyModal-modalContent {
    overflow: auto;
  }
}