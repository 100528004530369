@import '~sass-mq';
@import 'styles/variables.scss';

.AsideBox {
  position: relative;  
  background-color: rgba(0, 0, 0, 0.85);
  height: 100%;
  display: flex;
  flex-flow: column;

  @include mq($until: medium) {
    border-top: 1px solid $clrBorder;
  }

  @include mq($from: medium) {
    border-radius: 5px;
    width: 200px;
  }

  h1 {
    text-transform: uppercase;
    line-height: 1;
    position: relative;    
    margin: 10px auto 3px;
    font-size: 0.9rem;

    @include mq($until: small) {
      font-size: 0.8rem;
    }

    @include mq($from: medium) {
      padding: 15px;
      font-size: 1.22rem;
      letter-spacing: .015rem;
      margin: 0;
      overflow: hidden;
      // todo: why is the h1 obscuring the parent border-radius?;      
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;

      &::after {
        content: '';
        position: absolute;
        height: 3px;
        width: 100%;
        top: calc(50% - 2px);
        background-color: $clrSecondary;
        margin-left: 15px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url("../img/diag-bg2.png");
        background-repeat: repeat;
        opacity: 0.25;
      }
    }
  }

  .AsideBox-content {
    flex: 1;
    padding: 0 10px 10px;

    @include mq($from: medium) {
      padding: 5px 10px;
    }

    @include mq($until: medium) {
      &::after {
        content: '';
        width: 1px;
        background-color: $clrBorder;
        height: 100%;
        position: absolute;
        top: 0;
        left: -6px;
        z-index: 1;
      }

      &::before {
        content: '';
        width: 1px;
        background-color: $clrBorder;
        height: 100%;
        position: absolute;
        top: 0;
        left: 5px;
      }
    }
  }

  .AsideBox-progressBar {
    background-color: $clrSecondary;

    @include mq($until: medium) {
      width: 10px !important;
      position: absolute;
      left: -5px;
      bottom: 0;
      transition: height 0.6s linear;
      z-index: 1;
    }

    @include mq($from: medium) {
      height: 10px !important;
      transition: width 0.6s linear;
    }    
  }
}