@import '~sass-mq';
@import 'styles/variables.scss';

.Help {
  .Help-instructions {
    ul {
      margin-bottom: 20px;

      li {
        display: flex;
        align-items: center;

        .touchEnabled & {
          margin-bottom: 5px;
          align-items: start;
        }

        & >:first-child {
          display: flex;
          align-items: center;
          padding-right: 10px;
          flex-shrink: 0;

          .touchEnabled & {
            width: 125px;
            display: block;
          }

          svg {
            fill: $clrTx;
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}