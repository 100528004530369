// http://devfacts.com/media-queries-breakpoints-2020/
$mq-breakpoints: (
  small: 420px,
  medium: 768px,
  large: 992px,
  xLarge: 1200px,
);

$maxContentWidth: 1024px;

$clrSecondary: #14bcd9;
$clrTx: #b9c0c4;
$clrBorder: #0e2c44;;